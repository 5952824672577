import React from 'react';
import PropTypes from 'prop-types';
import { Banner } from './banner.css';

const _Banner = ({ children }) => (
  <Banner className="find-me">{children}</Banner>
);

_Banner.propTypes = {
  children: PropTypes.node.isRequired,
};

export default _Banner;
