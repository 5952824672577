import styled from 'styled-components';
import { offWhite, darkBlue } from 'constants/theme';
import MEDIA from 'helpers/mediaTemplates';

export const Banner = styled.div`
  max-width: 1400px;
  margin: 3rem auto;
  padding: 2rem 4rem;
  border-radius: 3rem;
  background-color: ${offWhite};
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  ${MEDIA.TABLET`
      padding: 2rem 1rem;
  `};

  ${MEDIA.PHONE`
      padding: 2rem 0rem;
  `};

  .banner-background {
    background-position: center center;
    width: 100%;
    border-radius: 3rem;
    overflow: hidden;

    &::before,
    &::after {
      ${MEDIA.TABLET`
      background-position: left center !important;
    `};
    }

    ${MEDIA.TABLET`
      background-position: left center !important;
    `};
  }

  .banner-content {
    margin: 3rem 4rem;
    padding: 2rem 4rem;
    border-radius: 3rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 10;
    color: white;

    &__title {
      font-size: 5.5rem;
      font-weight: 600;
      margin-top: 5rem;
      margin-bottom: 3rem;
    }

    &__about {
      margin-bottom: 0.5rem;
      font-weight: 600;
      font-size: 2rem;
    }

    &__start-time {
      font-size: 1.75rem;
      line-height: 2.5rem;
      font-style: italic;
      margin-bottom: 3rem;
      white-space: pre-wrap;
      /* font-family: 'Lyon display Web', sans-serif; */
    }

    &__start-date {
      font-size: 2.4rem;
      font-style: italic;
      white-space: pre-wrap;
      margin-bottom: 0.5rem;
      /* font-family: 'Lyon display Web', sans-serif; */
    }

    &__over {
      max-width: 600px;
      padding: 3rem;
      margin: 0 2rem 3rem;
      color: white;
      font-size: 2.4rem;
      font-style: italic;
      line-height: 1.5;

      strong {
        font-weight: bold;
        color: ${darkBlue} !important;
      }
    }

    ${MEDIA.TABLET`
      margin: 3rem auto;
      padding: .5rem .5rem;
    `};
  }

  .past-event {
    /* &::before, */
    &::after {
      opacity: 0.75 !important;
    }

    /* opacity: 0.1 !important; */
  }

  .banner-bottom {
    padding: 4rem;
    background: black;
    text-align: center;

    p,
    a {
      padding: 0;
      margin: 0;
    }

    p {
      color: #b1b1b1;
    }

    strong {
      color: white;
      font-weight: bold;
    }

    a {
      color: white;
      text-decoration: underline;
    }
  }
`;
